import React from 'react';
import { BaseButton, BaseButtonProps, BaseButtonStyled } from './BaseButton';
import tw, { styled } from 'twin.macro';

export const CustomOutlinePrimaryStyledButton = styled(BaseButtonStyled)`
  ${tw`bg-transparent text-primary border border-primary  focus:shadow-outline-yellow focus:outline-none`}
  &:disabled {
    ${tw`text-opacity-75 border-opacity-75`}
  }
  div {
    ${tw`text-primary`}
  }
`;

export const OutlinePrimaryButton = (
  props: Omit<BaseButtonProps, 'ButtonWrapper'>,
) => {
  return (
    <BaseButton {...props} ButtonWrapper={CustomOutlinePrimaryStyledButton} />
  );
};
