import React from 'react';
import tw, { styled } from 'twin.macro';
import { BaseButton, BaseButtonStyled, BaseButtonProps } from './BaseButton';
import { IconProps } from '../icons/createIcon';
import { VisuallyHidden } from 'components/VisuallyHidden';

export type IconButtonProps = Omit<BaseButtonProps, 'children'> & {
  label: string;
  Icon: React.FC<IconProps>;
};

export const CustomIconSquareStyled = styled(BaseButtonStyled)`
  ${tw`focus:shadow-outline-gray focus:outline-none leading-none`}
  &:disabled {
    ${tw`opacity-50`}
  }
  ${(props) => {
    const { size } = props;
    const wAH = size === 'base' ? 36 : 20;

    return `width: ${wAH}px; height:${wAH}px; padding:0;`;
  }}
`;

export const IconButton = ({
  Icon,
  label,
  size = 'base',
  ...props
}: Omit<IconButtonProps, 'ButtonWrapper'>) => {
  return (
    <BaseButton size={size} {...props} ButtonWrapper={CustomIconSquareStyled}>
      <VisuallyHidden>{label}</VisuallyHidden>
      <Icon size={size === 'base' ? 24 : 16} aria-hidden="true" />
    </BaseButton>
  );
};
