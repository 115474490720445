import React from 'react';
import { BaseButton, BaseButtonProps, BaseButtonStyled } from './BaseButton';
import tw, { styled } from 'twin.macro';

export const CustomPrimaryStyledButton = styled(BaseButtonStyled)`
  ${tw`bg-primary text-white focus:shadow-outline-yellow focus:outline-none`}
  &:disabled {
    ${tw`bg-opacity-75 text-white`}
  }
  div {
    ${tw`text-white`}
  }
`;

export const PrimaryButton = (
  props: Omit<BaseButtonProps, 'ButtonWrapper'>,
) => {
  return <BaseButton {...props} ButtonWrapper={CustomPrimaryStyledButton} />;
};
