import React from 'react';
import { keyframes } from 'styled-components';

import tw, { styled } from 'twin.macro';
import { VisuallyHidden } from 'components/VisuallyHidden';

const spin = keyframes({
  from: {
    transform: 'rotate(0deg)',
  },
  to: {
    transform: 'rotate(360deg)',
  },
});

const Container = styled.div<{ lineThickness: number }>`
  ${tw`inline-block rounded-full text-black border-solid`}
  border-width: ${(props) => props.lineThickness}px;
  border-color: currentColor;
  border-left-color: transparent;
  border-bottom-color: transparent;
  animation: ${spin} 0.45s linear infinite;
`;

export type SpinnerSize = 'xl' | 'lg' | 'md' | 'xs';

interface Props {
  className?: string;
  size?: SpinnerSize;
  thickness?: number;
}

export const Spinner = ({ size = 'md', thickness = 2, ...rest }: Props) => {
  return (
    <Container
      css={[
        size === 'xs' && tw`w-3 h-3`,
        size === 'md' && tw`w-5 h-5`,
        size === 'lg' && tw`w-8 h-8`,
        size === 'xl' && tw`w-12 h-12`,
      ]}
      lineThickness={thickness}
      {...rest}
    >
      <VisuallyHidden>Loading...</VisuallyHidden>
    </Container>
  );
};

export default Spinner;
