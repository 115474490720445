import React from 'react';
import { BaseButton, BaseButtonProps, BaseButtonStyled } from './BaseButton';
import tw, { styled } from 'twin.macro';

export const CustomSecondaryStyledButton = styled(BaseButtonStyled)`
  ${tw`bg-secondary text-white  focus:shadow-outline-gray focus:outline-none`};
  &:disabled {
    ${tw`bg-opacity-75`}
  }
  div {
    ${tw`text-white`}
  }
`;

export const SecondaryButton = (
  props: Omit<BaseButtonProps, 'ButtonWrapper'>,
) => {
  return <BaseButton {...props} ButtonWrapper={CustomSecondaryStyledButton} />;
};
