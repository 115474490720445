import React from 'react';
import tw, { styled } from 'twin.macro';
import Link from 'next/link';
import { UrlObject } from 'url';
import { Spinner } from 'components/Spinner';

export const Anchor = styled.a<{ disabled: boolean; block: boolean }>`
  ${(props) => (props.block ? tw`flex w-full` : tw`inline-flex`)}
  ${(props) => (props.disabled ? tw`pointer-events-none` : '')}
`;

type ButtonSize = 'xs' | 'sm' | 'base';
type ButtonType = 'button' | 'submit' | 'reset';

export type BaseButtonProps = {
  target?: string;
  href?: string;
  nextHref?: string | UrlObject;
  nextAs?: string | UrlObject;
  children: React.ReactNode;
  disabled?: boolean;
  isLoading?: boolean;
  size?: ButtonSize;
  block?: boolean;
  type?: ButtonType;
  prefetch?: boolean;
  testId?: string;
  ButtonWrapper: React.FunctionComponent<{
    size: ButtonSize;
    disabled: boolean;
    block: boolean;
    type?: ButtonType;
  }>;
} & React.HTMLAttributes<HTMLButtonElement>;

export const BaseButtonStyled = styled.button<{
  size: ButtonSize;
  disabled: boolean;
  block: boolean;
  type?: ButtonType;
}>`
  ${tw`font-medium items-center justify-center cursor-pointer flex-none leading-snug transition-all duration-200 rounded-sm whitespace-no-wrap relative`};
  ${(props) => (props.block ? tw`flex w-full` : tw`inline-flex`)}
  padding: ${(props) => {
    if (props.size === 'xs') {
      return '8px 16px';
    }
    if (props.size === 'sm') {
      return '10px 16px';
    }

    return '12px 16px';
  }};
  font-size: ${(props) => {
    return props.size === 'base' ? '16px' : '12px';
  }};

  &:disabled {
    pointer-events: none;
  }
`;

export const BaseButton = ({
  target,
  href,
  nextHref,
  type = 'button',
  size = 'base',
  disabled = false,
  prefetch,
  children,
  ButtonWrapper,
  isLoading = false,
  block = false,
  testId,
  nextAs,
  ...rest
}: BaseButtonProps) => {
  const button = (
    <ButtonWrapper
      type={type}
      size={size}
      {...rest}
      disabled={disabled}
      block={block}
      data-testid={testId}
    >
      <span css={[isLoading && tw`opacity-0`]} aria-hidden={isLoading}>
        {children}
      </span>
      {isLoading && (
        <span css={tw`absolute-center block leading-none`}>
          <Spinner size={size === 'base' ? 'md' : 'xs'} />
        </span>
      )}
    </ButtonWrapper>
  );

  if (href) {
    return (
      <Anchor
        href={href}
        target={target || '_blank'}
        rel={!target ? 'noopener noreferrer' : undefined}
        disabled={disabled}
        block={block}
        data-testid={testId}
      >
        {button}
      </Anchor>
    );
  }

  if (nextHref) {
    return (
      <Link href={nextHref} as={nextAs} prefetch={prefetch}>
        <Anchor disabled={disabled} block={block} data-testid={testId}>
          {button}
        </Anchor>
      </Link>
    );
  }

  return button;
};
