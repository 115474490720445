import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

type TruncateValue = 0 | 1 | 2 | 3 | 4 | 5;

export interface TextProps extends HTMLAttributes<HTMLElement> {
  as?:
    | 'p'
    | 'i'
    | 'u'
    | 'abbr'
    | 'del'
    | 'cite'
    | 'ins'
    | 's'
    | 'b'
    | 'strong'
    | 'em'
    | 'samp'
    | 'sub'
    | 'sup'
    | 'span';
  truncate?: TruncateValue;
}

function getTruncateClass(truncate: TruncateValue) {
  if (truncate === 1) {
    return 'truncate';
  }
  if (truncate === 2) {
    return 'truncate-2-lines';
  }
  if (truncate === 3) {
    return 'truncate-3-lines';
  }
  if (truncate === 4) {
    return 'truncate-4-lines';
  }
  if (truncate === 5) {
    return 'truncate-5-lines';
  }

  return '';
}

export const Text: React.FC<TextProps> = ({
  as = 'p',
  truncate = 0,
  className = '',
  ...rest
}) => {
  return React.createElement(as, {
    ...rest,
    className: classnames(className, getTruncateClass(truncate)),
  });
};
