import React from 'react';
import { BaseButton, BaseButtonProps, BaseButtonStyled } from './BaseButton';
import tw, { styled } from 'twin.macro';

export const CustomOutlineSecondaryStyledButton = styled(BaseButtonStyled)`
  ${tw`bg-transparent text-secondary border border-secondary focus:shadow-outline-gray focus:outline-none`}
  &:disabled {
    ${tw`text-opacity-75 border-opacity-75`}
  }
  div {
    ${tw`text-secondary`}
  }
`;

export const OutlineSecondaryButton = (
  props: Omit<BaseButtonProps, 'ButtonWrapper'>,
) => {
  return (
    <BaseButton {...props} ButtonWrapper={CustomOutlineSecondaryStyledButton} />
  );
};
