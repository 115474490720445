import React, { HTMLAttributes } from 'react';
import classnames from 'classnames';

interface Props extends HTMLAttributes<HTMLHeadingElement> {
  as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
  truncate?: boolean;
  isSerif?: boolean;
}

export const Heading: React.FC<Props> = ({
  as = 'h3',
  className,
  isSerif = false,
  truncate,
  ...rest
}) => {
  return React.createElement(as, {
    ...rest,
    className: classnames(className, { 'font-serif': isSerif, truncate }),
  });
};
