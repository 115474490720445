import React from 'react';
import { BaseButton, BaseButtonProps, BaseButtonStyled } from './BaseButton';
import tw, { styled } from 'twin.macro';

export const CustomLinkTextButtonStyledButton = styled(BaseButtonStyled)`
  ${tw`bg-transparent text-blue-500 focus:shadow-outline-blue focus:outline-none`};
  &:disabled {
    ${tw`text-opacity-75`}
  }
  div {
    ${tw`text-blue-500`}
  }
`;

export interface LinkTextButtonProps
  extends Omit<BaseButtonProps, 'ButtonWrapper'> {}

export const LinkTextButton = (props: LinkTextButtonProps) => {
  return (
    <BaseButton {...props} ButtonWrapper={CustomLinkTextButtonStyledButton} />
  );
};
